.App {
  text-align: center;
}

.container {
  margin: 0px auto;
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.logo{
  position: fixed;
  right:10%;
  bottom:10%;
}
